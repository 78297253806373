import { useState, useEffect } from 'react';
import { MantineProvider, Text, TextInput, Textarea, Box, Button, Checkbox, Switch, Radio, Group, SimpleGrid, Stepper, rem } from '@mantine/core';
import { useForm } from '@mantine/form';
import { DateInput } from '@mantine/dates';
import { API } from '../classes/API';
import { type } from 'os';
import { notifications } from '@mantine/notifications';
import { IconAlertCircle } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

export default function AppointmentForm(props:appointmentFormProps) {




  console.log('fam deets', props.data)

    const [active, setActive] = useState(0);
    const [childCount, setChildCount] = useState(1);
    const [highestStepVisited, setHighestStepVisited] = useState(active);
    const [nextButton, setNextButton] = useState('');
    const [formSent, setFormSent] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const navigate = useNavigate();

    const form = useForm({
      validateInputOnBlur: true,
      initialValues: {
        parentFirstName: '',
        parentLastName: '',
        parentPronoun: '',
        appointment: {
          partnerFirstName: '',
          partnerLastName: '',
          gpAddress1: '',
          gpAddress2: '',
          gpCity: '',
          gpCounty: '',
          gpPostcode: '',
          bloodClotting: '',
          babyMedicalTreatment: '',
          previouslyBreastFed:'',

          feedingWorries: '',
          pregnancyIssues: '',
          birthDescription: '',
          medication: '',
          // TODO this is duplicated??
          healthConcerns: '',
          mentalHalthConcerns: '',
          pumpConfirmation: '',
          ibclcContactMidwife: '',
          ibclcShareSummary: '',
          ibclcShareMedia: '',
          weeksBabyBorn: '',
          nicu: '',
          babyMedication: '',
          receivedAdvice: '',

          feedingBreastMilk: false,
          feedingExpressedBreastMilk: false,
          feedingFormulaMilk: false,
          feedingDonorMilk: false,
          feedingSolidFood: false,

          feedingHelpNippleShields: false,
          feedingHelpBottles: false,
          feedingHelpDummy: false,
          feedingHelpSNS: false,
          feedingHelpCup: false,
          feedingHelpFingerFeeding: false,
          // niceDateOfAppointment: '',
          // dateOfAppointment: '',
          unavailability: '',
        },
        niceDateOfBirth: new Date,
        dateOfBirth: '',
        email: '',
        address1: '',
        address2: '',
        city: '',
        county: '',
        postcode: '',
        phoneNumber: '',
        // babyDateOfBirth: '',
        namedMidwife: '',
        namedHealthVisitor: '',
        // FIXME - not set anywhere
        // pumpRequest: false,
        children: [
          {
            firstName:'',
            lastName:'',
            niceDateOfBirth:new Date,
            dateOfBirth:'',
            nhs:'',
            vitaminK:null,
            weights: [
              {
                weight:0,
                date:'',
                niceDate:new Date,
              },
              {
                weight:0,
                date:'',
                niceDate:new Date,
              },
              {
                weight:0,
                date:'',
                niceDate:new Date,
              },
              {
                weight:0,
                date:'',
                niceDate:new Date,
              },
              {
                weight:0,
                date:'',
                niceDate:new Date,
              },
            ],
          },
        ],
      },

      validate: (values) => {
        if (props.editable) {
        if (active === 0) {
          return {
            parentFirstName: values.parentFirstName.trim().length < 2 ? 'First name must include at least 2 characters' : null,
            parentLastName: values.parentLastName.length < 2 ? 'Last name must include at least 2 characters' : null,
            // niceDateOfBirth: values.niceDateOfBirth.length < 2 ? 'Select a date of birth' : null,
            email: values.email.length < 2 ? 'Please enter your email address' : null,
            address1: values.address1.trim().length < 2 ? 'Address 1 must include at least 2 characters' : null,
            city: values.city.trim().length < 2 ? 'Please enter your town/city' : null,
            county: values.county.trim().length < 2 ? 'Please enter your county' : null,
            postcode: values.postcode.trim().length < 2 ? 'Please enter your postcode' : null,
            phoneNumber: values.phoneNumber.trim().length < 2 ? 'Please enter your phone number' : null,
          };
        }

        if (active === 1) {

          console.log('feeding worries:',values.appointment.feedingWorries)

          const errors:Record<string,any> = {
            'appointment.feedingWorries': values.appointment.feedingWorries.trim().length < 2 ? 'Please fill this in' : null,
          };

          values.children.map((child, ind) => {
            errors['children.'+ind+'.firstName'] = values.children[ind].firstName.trim().length < 2 ? 'First name must include at least 2 characters' : null
            errors['children.'+ind+'.lastName'] = values.children[ind].lastName.trim().length < 2 ? 'Last name must include at least 2 characters' : null
            // errors['children.'+ind+'.niceDateOfBirth'] = values.children[ind].niceDateOfBirth.length < 2 ? 'Select a date of birth' : null
            errors['children.'+ind+'.nhs'] = values.children[ind].nhs.trim().length < 2 ? 'Please enter the NHS Number/Hospital Number' : null
            errors['children.'+ind+'.vitaminK'] = values.children[ind].vitaminK == undefined ? 'Please select if the baby has received Vitamin K' : null
          })

          return errors
        }

        if (active === 2) {
          return {
            'appointment.gpAddress1': values.appointment.gpAddress1.trim().length < 2 ? 'Address line 1 must include at least 2 characters' : null,
            'appointment.gpCity': values.appointment.gpCity.trim().length < 2 ? 'City must include at least 2 characters' : null,
            'appointment.gpCounty': values.appointment.gpCounty.trim().length < 2 ? 'County must include at least 2 characters' : null,
            'appointment.gpPostcode': values.appointment.gpPostcode.trim().length < 2 ? 'Postcode must include at least 2 characters' : null,
            'namedMidwife': values.namedMidwife.trim().length < 2 ? 'Name midwife must include at least 2 characters' : null,
            'appointment.bloodClotting': values.appointment.bloodClotting.trim().length < 2 ? 'This must include at least 2 characters' : null,
            'appointment.babyMedicalTreatment': values.appointment.babyMedicalTreatment.trim().length < 2 ? 'This must include at least 2 characters' : null,
          };
        }

        if (active === 3) {
          console.log('teeest',values.appointment.healthConcerns);
          return {
            'appointment.healthConcerns' : values.appointment.healthConcerns == null ? 'Please select if there are any health concerns' : null,
            'appointment.pumpConfirmation' : values.appointment.pumpConfirmation == null ? 'Please select an option' : null,
            'appointment.ibclcContactMidwife' : values.appointment.ibclcContactMidwife == null ? 'Please select an option' : null,
            'appointment.ibclcShareSummary' : values.appointment.ibclcShareSummary == null ? 'Please select an option' : null,
          };
        }
      }
        return {};
      },
    });

    const handleStepChange = (nextStep: number) => {
      const isOutOfBounds = nextStep > 4 || nextStep < 0;

      if (isOutOfBounds || form.validate().hasErrors) {
        return;
      }
      setActive(nextStep);
      setHighestStepVisited((hSC) => Math.max(hSC, nextStep));
      window.scrollTo(0, 0)
    };

   // Allow the user to freely go back and forth between visited steps.
   // TODO add error checking here
   const shouldAllowSelectStep = (step: number) => highestStepVisited >= step && active !== step; //&& !form.validate().hasErrors;

    const formValues = {}





    useEffect(
      () => {
        if (props.data !== undefined && form.values.parentFirstName == '') {
          form.setValues(props.data as Object)

          var milliseconds = Date.parse(props.data.dateOfBirth);
          var date = new Date(milliseconds);
          form.setFieldValue('niceDateOfBirth',date)
          setHighestStepVisited(4)
          props.data.children.forEach((c,i) => {
            milliseconds = Date.parse(c.dateOfBirth);
            date = new Date(milliseconds);
            form.setFieldValue(`children.${i}.niceDateOfBirth`,date)
            c.weights.forEach((w, wi) => {
              milliseconds = Date.parse(w.date);
              date = new Date(milliseconds);
              console.log('setting weight:',`children.${i}.weights.${wi}.niceDate`, date)
              form.setFieldValue(`children.${i}.weights.${wi}.niceDate`,date)
            })
          })
        }
      },
      [form]
    )



    const babies = form.values.children.map((_, index) => (
      <>
        <p>Baby name*</p>
        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
          <TextInput
            id="baby-first-name"
            aria-label="First Name"
            placeholder="First Name"
            type="input"
            name={`baby_${index}_first_name`}
            {...form.getInputProps(`children.${index}.firstName`)}
            readOnly={!props.editable}
          />

          <TextInput
            aria-label="Last Name"
            placeholder="Last Name"
            type="input"
            {...form.getInputProps(`children.${index}.lastName`)}
            readOnly={!props.editable}
          />
        </SimpleGrid>

        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
          <DateInput
            label="Baby Date of Birth"
            placeholder="Baby Date of Birth"
            //maw={400}
            //mx="auto"
            {...form.getInputProps(`children.${index}.niceDateOfBirth`)}
            my="md"
            required={true}
            readOnly={!props.editable}
          />
        </SimpleGrid>

        <p>Please give us a list of weights for baby:</p>

        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
          <DateInput
            // label="Date"
            placeholder="Date"
            {...form.getInputProps(`children.${index}.weights.0.niceDate`)}
            my="md"
            readOnly={!props.editable}
          />
          <TextInput
            placeholder="Weight"
            type="input"
            my="md"
            rightSection="kg"
            {...form.getInputProps(`children.${index}.weights.0.weight`)}
            readOnly={!props.editable}
          />
        </SimpleGrid>

        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
          <DateInput
            placeholder="Date"
            {...form.getInputProps(`children.${index}.weights.1.niceDate`)}
            my="md"
            readOnly={!props.editable}
          />
          <TextInput
            placeholder="Weight"
            type="input"
            my="md"
            rightSection="kg"
            {...form.getInputProps(`children.${index}.weights.1.weight`)}
            readOnly={!props.editable}
          />
        </SimpleGrid>

        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
          <DateInput
            placeholder="Date"
            {...form.getInputProps(`children.${index}.weights.2.niceDate`)}
            readOnly={!props.editable}
            my="md"
          />
          <TextInput
            placeholder="Weight"
            type="input"
            my="md"
            rightSection="kg"
            {...form.getInputProps(`children.${index}.weights.2.weight`)}
            readOnly={!props.editable}
          />
        </SimpleGrid>

        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
          <DateInput
            placeholder="Date"
            {...form.getInputProps(`children.${index}.weights.3.niceDate`)}
            readOnly={!props.editable}
            my="md"
          />
          <TextInput
            placeholder="Weight"
            type="input"
            my="md"
            rightSection="kg"
            {...form.getInputProps(`children.${index}.weights.3.weight`)}
            readOnly={!props.editable}
          />
        </SimpleGrid>

        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
          <DateInput
            placeholder="Date"
            {...form.getInputProps(`children.${index}.weights.4.niceDate`)}
            readOnly={!props.editable}
            my="md"
          />
          <TextInput
            placeholder="Weight"
            type="input"
            my="md"
            rightSection="kg"
            {...form.getInputProps(`children.${index}.weights.4.weight`)}
            readOnly={!props.editable}
          />
        </SimpleGrid>

        <TextInput
          label="Baby NHS Number/Hospital Number"
          aria-label="Baby NHS Number/Hospital Number"
          placeholder=""
          type="text"
          required={true}
          my="md"
          {...form.getInputProps(`children.${index}.nhs`)}
          readOnly={!props.editable}
          />

        <Radio.Group
          {...form.getInputProps(`children.${index}.vitaminK`)}
          label="Has Baby had Vitamin K injection or 2 doses of Oral vitamin K"
          //description="This is anonymous"
          withAsterisk
            my="md"
            value={`${form.values.children[index].vitaminK}`}
            onChange={
              (e) => {
                if (props.editable) {
                  form.getInputProps(`children.${index}.vitaminK`).onChange(e)
                }
              }
            }
        >
          <Group mt="xs">
            <Radio value="true" label="Yes"
            readOnly={!props.editable}
/>
            <Radio value="false" label="No"
            readOnly={!props.editable}
/>
          </Group>
        </Radio.Group>
      </>
    ));







    return (
        <>
      <Text ta="center">
        <h1>Book an appointment</h1>
      </Text>
      <form onSubmit={form.onSubmit(
        (values, _event) => {

          if (props.editable) {


          const parentDOB = values.niceDateOfBirth
          values.dateOfBirth = parentDOB.getFullYear()+'-'+(parentDOB.getMonth()+1)+'-'+parentDOB.getDate()
          // const appointmentDate = new Date(values.appointment.niceDateOfAppointment)
          // values.appointment.dateOfAppointment = appointmentDate.getFullYear()+'-'+(appointmentDate.getMonth()+1)+'-'+appointmentDate.getDate()+' 00:00:00'

          for(let i=0; i<values.children.length;i++){
            const tmpDate = values.children[i].niceDateOfBirth
            values.children[i].dateOfBirth = tmpDate.getFullYear()+'-'+(tmpDate.getMonth()+1)+'-'+tmpDate.getDate()
            // values.babyFirstName[i] = values.babies[i].firstName
            // values[`babyFirstName1`] = values.babies[i].firstName
          }

          var temp = new Date()
          var babycount = values.children.length
          for (var i = 0; i < babycount; i++) {
            for (var j = 0; j < values.children[i].weights.length; j++) {

              if (values.children[i].weights[j].date != '') {
                temp = values.children[i].weights[j].niceDate
                values.children[i].weights[j].date = temp.getFullYear()+'-'+(temp.getMonth()+1)+'-'+temp.getDate()
              } else {
                values.children[i].weights[j].date = '';
                values.children[i].weights[j].niceDate = new Date;
              }
            }
          }
          console.log('vals',values);
          setSubmitLoading(true)
          API.post('/appointment',values).then((response) =>{
            console.log('submit response', response)
            if (response.status === 201) {

              navigate('/appointment/confirmation');


              // setFormSent(true)
              // setSubmitLoading(false)
            } else {
              // Failed
              notifications.update({
                id:'setUser',
                color: 'red',
                message: 'Error saving the appointment',
                icon: <IconAlertCircle style={{ width: rem(18), height: rem(18) }} />,
                loading: false,
                autoClose: 2000,
              });
              setSubmitLoading(false)
            }
          })


        }

        },
        (validationErrors, _values, _event) => {
          console.log('ERRORS',validationErrors)
          console.log('FORM VALS',_values)
        }
      )}>

        <Stepper active={active} onStepClick={setActive} breakpoint="sm">
          <Stepper.Step
            label="About you"
            description=""
            allowStepSelect={shouldAllowSelectStep(0)}
          >
            <h2>About you</h2>
            <p>Parent's full name</p>
            <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
              <TextInput
                id="parent-first-name"
                aria-label="First Name"
                placeholder="First Name"
                type="input"
                name="parent-first-name"
                // disabled={!props.editable}
                {...form.getInputProps('parentFirstName')}
                readOnly={!props.editable}
              />
              <TextInput
                id="parent-last-name"
                aria-label="Last Name"
                placeholder="Last Name"
                type="input"
                name="parent-last-name"
                {...form.getInputProps('parentLastName')}
                readOnly={!props.editable}
              />
            </SimpleGrid>
            <TextInput
              id="parent-pronoun"
              aria-label="Pronoun"
              placeholder="Pronoun"
              type="input"
              name="parent-pronoun"
              {...form.getInputProps('parentPronoun')}
              readOnly={!props.editable}

            />

            <p>Partner's full name</p>
            <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
              <TextInput
                id="partner-first-name"
                aria-label="First Name"
                placeholder="First Name"
                type="input"
                name="partner-first-name"
                {...form.getInputProps('appointment.partnerFirstName')}
                readOnly={!props.editable}
              />

              <TextInput
                id="partner-last-name"
                aria-label="Last Name"
                placeholder="Last Name"
                type="input"
                name="partner-last-name"
                {...form.getInputProps('appointment.partnerLastName')}
                readOnly={!props.editable}
              />
            </SimpleGrid>

            <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]} my="md">
              <DateInput
                label="Breastfeeding parents date of birth"
                placeholder="Breastfeeding parents date of birth"
                required={true}
                // maw={400}
                // mx="auto"
                {...form.getInputProps('niceDateOfBirth')}
                readOnly={!props.editable}
              />
            </SimpleGrid>

            <TextInput
              label="Email Address"
              aria-label="Email Address"
              placeholder="example@example.com"
              type="email"
              {...form.getInputProps('email')}
              my="md"
              readOnly={!props.editable}
            />

            <p>Address</p>
            <TextInput
              aria-label="Address Line 1"
              placeholder="Address Line 1"
              type="text"
              required={true}
              {...form.getInputProps('address1')}
              readOnly={!props.editable}
            />
            <TextInput
              aria-label="Address Line 2"
              placeholder="Address Line 2"
              type="text"
              {...form.getInputProps('address2')}
              readOnly={!props.editable}
            />
            <TextInput
              aria-label="Town/City"
              placeholder="Town/City"
              type="text"
              required={true}
              {...form.getInputProps('city')}
              readOnly={!props.editable}
            />
            <TextInput
              aria-label="County"
              placeholder="County"
              type="text"
              {...form.getInputProps('county')}
              readOnly={!props.editable}
            />
            <TextInput
              aria-label="Postcode"
              placeholder="Postcode"
              type="text"
              required={true}
              {...form.getInputProps('postcode')}
              readOnly={!props.editable}
            />
            <TextInput
              aria-label="Phone Number"
              placeholder="Phone Number"
              type="text"
              {...form.getInputProps('phoneNumber')}
              readOnly={!props.editable}
            />
          </Stepper.Step>
          <Stepper.Step
            label="About baby"
            description=""
            allowStepSelect={shouldAllowSelectStep(1)}
          >
            <h2>About baby</h2>
            {babies}
            {form.values.children.length < 3 && props.editable &&
              <Group position="center" mt="md">
                <Button onClick={() => {
                  form.insertListItem(
                    'children',
                    {
                      firstName:'',
                      lastName:'',
                      dateOfBirth:'',
                      nhs:'',
                      vitaminK:null,
                      weights: [
                        {
                          weight:0,
                          date:'',
                          niceDate:new Date,
                        },
                        {
                          weight:0,
                          date:'',
                          niceDate:new Date,
                        },
                        {
                          weight:0,
                          date:'',
                          niceDate:new Date,
                        },
                        {
                          weight:0,
                          date:'',
                          niceDate:new Date,
                        },
                        {
                          weight:0,
                          date:'',
                          niceDate:new Date,
                        },
                      ],
                    }
                  )
                }}>
                  Add additional baby
                </Button>
              </Group>
            }


            <p>What are you currently feeding baby?</p>
            <Checkbox
              label="Breastmilk"
              my="xs"
              {...form.getInputProps('appointment.feedingBreastMilk', { type: 'checkbox' })}
              readOnly={!props.editable}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.feedingBreastMilk`).onChange(e)
                  }
                }
              }

              value={`${form.values.appointment.feedingBreastMilk}`}
            />
            <Checkbox
              label="Expressed breastmilk"
              my="xs"
              {...form.getInputProps('appointment.feedingExpressedBreastMilk', { type: 'checkbox' })}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.feedingExpressedBreastMilk`).onChange(e)
                  }
                }
              }
            />
            <Checkbox
              label="Formula milk"
              my="xs"
              {...form.getInputProps('appointment.feedingFormulaMilk', { type: 'checkbox' })}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.feedingFormulaMilk`).onChange(e)
                  }
                }
              }
            />
            <Checkbox
              label="Donor milk"
              my="xs"
              {...form.getInputProps('appointment.feedingDonorMilk', { type: 'checkbox' })}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.feedingDonorMilk`).onChange(e)
                  }
                }
              }
            />
            <Checkbox
              label="Solid food"
              my="xs"
              {...form.getInputProps('appointment.feedingSolidFood', { type: 'checkbox' })}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.feedingSolidFood`).onChange(e)
                  }
                }
              }
            />

            <p>Are you currently using anything to help you with feeding baby?</p>
            <Checkbox
              label="Nipple shields"
              my="xs"
              {...form.getInputProps('appointment.feedingHelpNippleShields', { type: 'checkbox' })}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.feedingHelpNippleShields`).onChange(e)
                  }
                }
              }
            />
            <Checkbox
              label="Bottles"
              my="xs"
              {...form.getInputProps('appointment.feedingHelpBottles', { type: 'checkbox' })}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.feedingHelpBottles`).onChange(e)
                  }
                }
              }
            />
            <Checkbox
              label="Dummy"
              my="xs"
              {...form.getInputProps('appointment.feedingHelpDummy', { type: 'checkbox' })}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.feedingHelpDummy`).onChange(e)
                  }
                }
              }
            />
            <Checkbox
              label="SNS"
              my="xs"
              {...form.getInputProps('appointment.feedingHelpSNS', { type: 'checkbox' })}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.feedingHelpSNS`).onChange(e)
                  }
                }
              }
            />
            <Checkbox
              label="Cup"
              my="xs"
              {...form.getInputProps('appointment.feedingHelpCup', { type: 'checkbox' })}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.feedingHelpCup`).onChange(e)
                  }
                }
              }
            />
            <Checkbox
              label="Finger feeding"
              my="xs"
              {...form.getInputProps('appointment.feedingHelpFingerFeeding', { type: 'checkbox' })}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.feedingHelpFingerFeeding`).onChange(e)
                  }
                }
              }
            />

            <Textarea
              label="Please tell us what is worrying you about feeding?"
              aria-label="Please tell us what is worrying you about feeding?"
              placeholder=""
              required={true}
              my="md"
              {...form.getInputProps('appointment.feedingWorries')}
              readOnly={!props.editable}
            />
            <TextInput
              label="How many weeks was your baby when they were born?"
              aria-label="How many weeks was your baby when they were born?"
              placeholder=""
              type="text"
              my="md"
              {...form.getInputProps('appointment.weeksBabyBorn')}
              readOnly={!props.editable}
            />

          </Stepper.Step>
          <Stepper.Step
            label="Medical"
            description=""
            allowStepSelect={shouldAllowSelectStep(2)}
          >
            <h2>Medical details</h2>
            <p>GP Surgery Name and Address*</p>
            <TextInput
              aria-label="Address line 1"
              placeholder="Address line 1"
              type="text"
              my="md"
              {...form.getInputProps('appointment.gpAddress1')}
              readOnly={!props.editable}
            />
            <TextInput
              my="md"
              aria-label="Address line 2"
              placeholder="Address line 2"
              type="text"
              {...form.getInputProps('appointment.gpAddress2')}
              readOnly={!props.editable}
            />
            <TextInput
              aria-label="Town/City"
              placeholder="Town/City"
              type="text"
              my="md"
              {...form.getInputProps('appointment.gpCity')}
              readOnly={!props.editable}
            />
            <TextInput
              aria-label="County"
              placeholder="County"
              type="text"
              my="md"
              {...form.getInputProps('appointment.gpCounty')}
              readOnly={!props.editable}
              />
            <TextInput
              aria-label="Postcode"
              placeholder="Postcode"
              type="text"
              my="md"
              {...form.getInputProps('appointment.gpPostcode')}
              readOnly={!props.editable}
            />


            <TextInput
              label="Named midwife and team"
              aria-label="Named midwife and team"
              placeholder=""
              type="text"
              required={true}
              my="md"
              {...form.getInputProps('namedMidwife')}
              readOnly={!props.editable}
            />
            <TextInput
              label="Named Health visitor"
              aria-label="Named Health visitor"
              placeholder=""
              type="text"
              my="md"
              {...form.getInputProps('namedHealthVisitor')}
              readOnly={!props.editable}
            />
            <TextInput
              label="Is there any history of blood clotting disorders in the family?"
              aria-label="Is there any history of blood clotting disorders in the family?"
              placeholder=""
              type="text"
              my="md"
              required={true}
              {...form.getInputProps('appointment.bloodClotting')}
              readOnly={!props.editable}
            />
            <TextInput
              label="Is baby undergoing any medical treatment or requiring any follow ups"
              aria-label="Is baby undergoing any medical treatment or requiring any follow ups"
              placeholder=""
              type="text"
              my="md"
              required={true}
              {...form.getInputProps('appointment.babyMedicalTreatment')}
              readOnly={!props.editable}
            />

            <TextInput
              label="Did you have any issues in your pregnancy?"
              aria-label="Did you have any issues in your pregnancy?"
              placeholder=""
              type="text"
              my="md"
              {...form.getInputProps('appointment.pregnancyIssues')}
              readOnly={!props.editable}
            />

            <Textarea
              label="Please describe your birth. Were you induced? How long was labour? What type of birth did you have? (Vaginal birth, forceps, ventouse, emergency or elective caesarean)"
              aria-label="Please describe your birth. Were you induced? How long was labour? What type of birth did you have? (Vaginal birth, forceps, ventouse, emergency or elective caesarean)"
              placeholder=""
              my="md"
              {...form.getInputProps('appointment.birthDescription')}
              readOnly={!props.editable}
            />


            <Textarea
              label="Do you take any medication? Do you have any health concerns?"
              aria-label="Do you take any medication? Do you have any health concerns?"
              placeholder=""
              my="md"
              {...form.getInputProps('appointment.medication')}
              readOnly={!props.editable}
            />

            <Textarea
              label="Do you have any mental health concerns (past or/and current)?"
              aria-label="Do you have any mental health concerns (past or/and current)?"
              placeholder=""
              my="md"
              {...form.getInputProps('appointment.mentalHealthConcerns')}
              readOnly={!props.editable}
            />

            <TextInput
              label="If your baby spent any time on NICU then please tell us how long for, and for what reason"
              aria-label="If your baby spent any time on NICU then please tell us how long for, and for what reason"
              placeholder=""
              type="text"
              my="md"
              {...form.getInputProps('appointment.nicu')}
              readOnly={!props.editable}
            />

            <Textarea
              label="Is baby on any medication? Has baby recently taken any medication?"
              aria-label="Is baby on any medication? Has baby recently taken any medication?"
              placeholder=""
              my="md"
              {...form.getInputProps('appointment.babyMedication')}
              readOnly={!props.editable}
            />

          </Stepper.Step>
          <Stepper.Step
            label="Misc"
            description=""
            allowStepSelect={shouldAllowSelectStep(3)}
          >
            <h2>Miscellaneous</h2>

            <p>What is concerning you?</p>
            <Checkbox
              label="Latching baby to breast"
              my="xs"
              {...form.getInputProps('appointment.concernsLatching', { type: 'checkbox' })}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.concernsLatching`).onChange(e)
                  }
                }
              }
            />
            <Checkbox
              label="Weight gain"
              my="xs"
              {...form.getInputProps('appointment.concernsWeightGain', { type: 'checkbox' })}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.concernsWeightGain`).onChange(e)
                  }
                }
              }

            />
            <Checkbox
              label="Tongue tie"
              my="xs"
              {...form.getInputProps('appointment.concernsTongueTie', { type: 'checkbox' })}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.concernsTongueTie`).onChange(e)
                  }
                }
              }

            />
            <Checkbox
              label="Milk supply"
              my="xs"
              {...form.getInputProps('appointment.concernsMilkSupply', { type: 'checkbox' })}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.concernsMilkSupply`).onChange(e)
                  }
                }
              }

            />
            <Checkbox
              label="Expressing breastmilk"
              my="xs"
              {...form.getInputProps('appointment.concernsExpressingBreastMilk', { type: 'checkbox' })}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.concernsExpressingBreastMilk`).onChange(e)
                  }
                }
              }

            />
            <Checkbox
              label="Weaning"
              my="xs"
              {...form.getInputProps('appointment.concernsWeaning', { type: 'checkbox' })}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.concernsWeaning`).onChange(e)
                  }
                }
              }

            />
            <Checkbox
              label="Combination feeding"
              my="xs"
              {...form.getInputProps('appointment.concernsCombinationFeeding', { type: 'checkbox' })}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.concernsCombinationFeeding`).onChange(e)
                  }
                }
              }

            />
            <Checkbox
              label="Aversion"
              my="xs"
              {...form.getInputProps('appointment.concernsAversion', { type: 'checkbox' })}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.concernsAversion`).onChange(e)
                  }
                }
              }

            />
            <Checkbox
              label="Breastfeeding grief & trauma from previous baby"
              my="xs"
              {...form.getInputProps('appointment.concernsPreviousBabyTrauma', { type: 'checkbox' })}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.concernsPreviousBabyTrauma`).onChange(e)
                  }
                }
              }

            />

            <Textarea
              label="Have you previously breastfed another child? Please tell us about your experiences."
              aria-label="Have you previously breastfed another child? Please tell us about your experiences."
              placeholder=""
              my="md"
              {...form.getInputProps('appointment.previouslyBreastFed')}
              readOnly={!props.editable}
            />

            <Radio.Group
              name="healthConcerns"
              {...form.getInputProps('appointment.healthConcerns')}
              label="Do you have any immediate concerns about the health of your baby or yourself? If so, please contact your midwife/health visitor/GP immediately."
              withAsterisk
              my="md"
              value={`${form.values.appointment.healthConcerns}`}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.healthConcerns`).onChange(e)
                  }
                }
              }
            >
              <Group mt="xs">
                <Radio value="true" label="Yes" />
                <Radio value="false" label="No" />
              </Group>
            </Radio.Group>

            <Textarea
              label="What support and advice have you received from your midwife or health visiting team up until this point? Are you on a feeding plan set by the Midwives/Health Visitors or Paediatricians?"
              aria-label="What support and advice have you received from your midwife or health visiting team up until this point? Are you on a feeding plan set by the Midwives/Health Visitors or Paediatricians?"
              placeholder=""
              my="md"
              {...form.getInputProps('appointment.receivedAdvice')}
              readOnly={!props.editable}
            />

            <Radio.Group
              name="breastPump"
              {...form.getInputProps('appointment.pumpConfirmation')}
              label="Do you understand if we loan you a breast pump this loan is for one calendar month? Pumps are required to be returned quickly at the end of the loan to enable us to support more families."
              my="md"
              withAsterisk
              value={`${form.values.appointment.pumpConfirmation}`}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.pumpConfirmation`).onChange(e)
                  }
                }
              }
            >
              <Group mt="xs">
                <Radio value="true" label="Yes" />
                <Radio value="false" label="No" />
              </Group>
            </Radio.Group>

            <Radio.Group
              name="consentContact"
              {...form.getInputProps('appointment.ibclcContactMidwife')}
              label="Do you give consent for the IBCLC to contact your Midwife/Health Visitor or GP if required"
              my="md"
              withAsterisk
              value={`${form.values.appointment.ibclcContactMidwife}`}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.ibclcContactMidwife`).onChange(e)
                  }
                }
              }
            >
              <Group mt="xs">
                <Radio value="true" label="Yes" />
                <Radio value="false" label="No" />
              </Group>
            </Radio.Group>

            <Radio.Group
              name="consentSummery"
              {...form.getInputProps('appointment.ibclcShareSummary')}
              label="Do you give your consent for the IBCLC to share their written summary of your appointment to your Midwife/Health Visitor or James Paget University Hospital Infant Feeding Team"
              my="md"
              withAsterisk
              value={`${form.values.appointment.ibclcShareSummary}`}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.ibclcShareSummary`).onChange(e)
                  }
                }
              }
            >
              <Group mt="xs">
                <Radio value="true" label="Yes" />
                <Radio value="false" label="No" />
              </Group>
            </Radio.Group>
            <Radio.Group
              name="consentShared"
              {...form.getInputProps('appointment.ibclcShareMedia')}
              label="At times IBCLC's will need to ask the opinion of other practitioners. Are you happy for your information, photos and videos to be shared if needed? Personal data will not be shared in this situation"
              my="md"
              withAsterisk
              value={`${form.values.appointment.ibclcShareMedia}`}
              onChange={
                (e) => {
                  if (props.editable) {
                    form.getInputProps(`appointment.ibclcShareMedia`).onChange(e)
                  }
                }
              }
            >
              <Group mt="xs">
                <Radio value="true" label="Yes" />
                <Radio value="false" label="No" />
              </Group>
            </Radio.Group>

          </Stepper.Step>

          <Stepper.Completed>
            <h2>Thank you</h2>
            <p>Thanks for filling in the appointment form.</p>
            {
              formSent
              ?
              <p>Your information has been submitted, we'll be in contact soon</p>
              :
              <>
              {/*
                <DateInput
                  label="Please select a preferred date for your appointment"
                  placeholder="Preferred appointment date"
                  required={true}
                  // maw={400}
                  // mx="auto"
                  {...form.getInputProps('appointment.niceDateOfAppointment')}
                />
              */}

                <Textarea
                  label="Do you have any days in the next week you are unavailable?"
                  aria-label="Do you have any days in the next week you are unavailable?"
                  placeholder=""
                  my="md"
                  {...form.getInputProps('appointment.unavailability')}
                  readOnly={!props.editable}
                  />

                {props.editable &&
                <>
                  <p>Please press the button below to send us the information.</p>
                  <Button type="submit" size="md" loading={submitLoading}>
                    Book appointment
                  </Button>
                </>
                }
              </>
            }

          </Stepper.Completed>
        </Stepper>

        <Group position="center" mt="xl" mb="md">
          {active > 0 && !formSent ?
          <Button variant="default" onClick={() => handleStepChange(active - 1)}>Back</Button>
          :''
          }
          {active <= 3 ?
          <Button onClick={() => handleStepChange(active + 1)}>Next step</Button>
          :''
          }
        </Group>
        <div id="sidebar">
            <div>
              <div
                id="search-spinner"
                aria-hidden
                hidden={true}
              />
              <div
                className="sr-only"
                aria-live="polite"
              ></div>

          </div>
        </div>
        <div id="detail"></div>
      </form>
        </>
    )
}
